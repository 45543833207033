import styled from "styled-components";
import Illustration from "../assets/images/bg-2.png";
import Logo from "../assets/images/logo-dark.png";
import { ReactComponent as Linkedin } from "../assets/images/linkedin.svg";
import { ReactComponent as Discord } from "../assets/images/discord.svg";
import { ReactComponent as Telegram } from "../assets/images/telegram.svg";
import { ReactComponent as ArrowUp } from "../assets/images/arrow-up.svg";

const Footer = () => {
  const goTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <Style>
      <div className="container" data-aos="fade-up">
        <div className="content">
          <header>
            <h2 className="s">
              Orderbook Liquidity. <span>Reimagined.</span>
            </h2>
          </header>
          <div className="prefooter">
            <SocialMedia>
              <a href="https://discord.gg/elixirnetwork">
                <Discord />
              </a>
              <a href="https://t.me/elixir_network">
                <Telegram />
              </a>
              <a href="https://www.linkedin.com/company/elixir-protocol/">
                <Linkedin />
              </a>
              <button onClick={goTop}>
                <ArrowUp />
              </button>
            </SocialMedia>
            <div>
              <p>© 2022 Elixir Technologies Ltd.</p>
              <a href="/" className="sm">
                TERMS
              </a>
              <a href="/" className="sm">
                PRIVACY
              </a>
            </div>
            <img src={Logo} alt="Logo" className="logo" width={123} height={32} />
          </div>
        </div>
      </div>
    </Style>
  );
};
export default Footer;

const Style = styled.footer`
  padding: 100px 3vw;
  color: #020101;
  a {
    color: #020101;
  }
  h2 {
    text-align: center;
    span {
      font-family: "Signifier LightItalic";
    }
  }
  .content {
    background-color: #fff;
    border-radius: 20px;
    position: relative;
    background-image: url(${Illustration});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    height: clamp(600px, 60vw, 790px);
    padding: 128px 42px 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  header {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .prefooter {
    display: flex;
    align-items: center;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 20px;
    }
  }
  @media (max-width: 768px) {
    padding: 30px 20px;
    .content {
      padding: 20px;
      background-position: center 40%;
      background-size: 130%;
    }
    .prefooter {
      flex-direction: column-reverse;
      gap: 20px;
      .logo {
        order: 1;
      }
    }
  }
`;

const SocialMedia = styled.div`
  display: flex;
  align-items: center;
  gap: 17px;
  a,
  button {
    padding: 0;
    width: 36px;
    height: 36px;
    border-radius: 50px;
    border: 1px solid #020101;
    display: flex;
    align-items: center;
    justify-content: center;
    svg path {
      fill: #020101;
    }
    :hover {
      background-color: #020101;
      svg path {
        fill: #fff;
      }
    }
  }

  @media (max-width: 768px) {
    order: 1;
  }
`;
