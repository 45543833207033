import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "aos/dist/aos.css";
import "swiper/css";
import "./assets/sass/style.sass";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
