import styled from "styled-components";
import img from "../assets/images/logo77.png";
import Background from "../assets/images/bg-3.png";

const Investors = () => {
  const investors = ["ChapterOne", "Hack VC", "FalconX", "Arthur Hayes", "Kucoin", "Commonwealth"];
  return (
    <Style data-aos="fade-up">
      <div className="container">
        <div className="content">
          <div>
            <img src={img}/>
            <h3>The Investors Helping Revolutionize Crypto Liquidity</h3>
          </div>
          <footer>
            <div className="text1">
              {investors.map((investor, index) => (
                <h4 key={index}>{investor}</h4>
              ))}
            </div>
            <div className="text2">
              {investors.map((investor, index) => (
                <h4 key={index}>{investor}</h4>
              ))}
            </div>
          </footer>
        </div>
      </div>
    </Style>
  );
};
export default Investors;

const Style = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  .content {
    margin-top: -235px;
    background: #020101;
    border: 1px solid #ffffff;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    > div {
      background-image: url(${Background});
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-top: clamp(40px, 10vw, 141px);
      padding-bottom: clamp(80px, 14vw, 250px);
      @media (max-width: 768px) {
        svg {
          width: 40px;
          height: 40px;
        }
      }
      h3 {
        margin-top: 47px;
        width: 60%;
        @media (max-width: 768px) {
          width: 95%;
        }
      }
    }
  }

  h4 {
    color: #020101;
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    ::after {
      content: "";
      width: 8px;
      height: 8px;
      background: #020101;
      display: inline-block;
      margin-inline: clamp(15px, 4vw, 57px);
    }
  }
  footer {
    background-color: #fff;
    width: 100%;
    padding-top: 64px;
    padding-bottom: 64px;
    display: flex;
    align-items: center;
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .text1 {
    -webkit-animation: scrolling-left1-mob 20s linear infinite;
    animation: scrolling-left1 20s linear infinite;
  }
  .text2 {
    -webkit-animation: scrolling-left2-mob 20s linear infinite;
    animation: scrolling-left2 20s linear infinite;
    animation-delay: 10s;
  }
  @keyframes scrolling-left1 {
    0% {
      transform: translateX(100%);
      -webkit-transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
      -webkit-transform: translateX(-100%);
    }
  }
  @keyframes scrolling-left2 {
    0% {
      transform: translateX(0%);
      -webkit-transform: translateX(0%);
    }
    100% {
      transform: translateX(-200%);
      -webkit-transform: translateX(-200%);
    }
  }

  @-webkit-keyframes scrolling-left1-mob {
    0% {
      -webkit-transform: translateX(100%);
    }
    100% {
      -webkit-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes scrolling-left2-mob {
    0% {
      -webkit-transform: translateX(0%);
    }
    100% {
      -webkit-transform: translateX(-200%);
    }
  }
`;
