import React from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, A11y } from "swiper";

import { ReactComponent as Grid } from "../assets/images/grid.svg";
import { ReactComponent as GridMobile } from "../assets/images/grid-mobile.svg";
import { ReactComponent as ArrowLeft } from "../assets/images/arrow-left.svg";
import { ReactComponent as ArrowReft } from "../assets/images/arrow-right.svg";
SwiperCore.use([Navigation, A11y]);

const Carousel = () => {
  const data = [
    {
      title: "50+",
      text: "DApps utilizing Elixir for market making",
    },
    {
      title: "20+",
      text: "Native backend DeFi integrations",
    },
    {
      title: "$300m+",
      text: "Testnet real trading volume",
    },
  ];
  const params = {
    navigation: {
      nextEl: ".next",
      prevEl: ".prev",
    },
  };
  return (
    <Style data-aos="fade-in">
      <div className="nav">
        <button className="prev">
          <ArrowLeft />
        </button>
        <button className="next">
          <ArrowReft />
        </button>
      </div>
      <Swiper
        slidesPerView="auto"
        spaceBetween={123}
        navigation={params.navigation}
        freeMode={{
          enabled: true,
          sticky: true,
        }}
      >
        {data.map((item, index) => (
          <SwiperSlide key={index}>
            <Card title={item.title} text={item.text} />
          </SwiperSlide>
        ))}
      </Swiper>
    </Style>
  );
};
export default Carousel;

const Style = styled.section`
  margin-top: 50px;
  .nav {
    display: flex;
    align-items: center;
    gap: 42px;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      display: none;
    }
    button {
      width: 83px;
      height: 83px;
      border-radius: 50px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #d3d3d3;
      :hover {
        background-color: #020101;
        svg path {
          fill: #fff;
        }
      }
    }
  }
  .swiper {
    overflow: hidden;
  }
  .swiper-slide {
    max-width: 494px;
    height: 371px;
    @media (max-width: 768px) {
      width: 230px;
      height: 267px;
      :not(:last-child) {
        margin-right: 50px !important;
      }
    }
  }
  @media (max-width: 768px) {
    margin-top: 100px;
  }
`;

const Card = ({ title, text }) => {
  return (
    <CardStyle>
      <div>
        <h1 className="display-1">{title}</h1>
        <p className="body-2">{text}</p>
      </div>
      <Grid />
      <GridMobile className="mobile" />
    </CardStyle>
  );
};

const CardStyle = styled.div`
  position: relative;
  border-radius: 10px;
  width: 100%;
  height: 100%;
  > div {
    position: absolute;
    z-index: 1;
    top: 43px;
    p {
      margin-top: 30px;
      max-width: 453px;
      @media (max-width: 768px) {
        margin-top: 10px;
      }
    }
  }
  svg {
    position: relative;
    left: 80px;
    width: 100%;
    height: 100%;
    @media (max-width: 768px) {
      left: 30px;
      display: none;
    }
  }
  .mobile {
    display: none;
    @media (max-width: 768px) {
      display: block;
    }
  }
`;
