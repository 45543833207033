import styled from "styled-components";
import Background from "../assets/images/bg-1.png";

const MarketingMaking = () => {
  const data = [
    {
      title: "Paying millions for minimal liquidity",
      text: "Exchanges pay millions per year for liquidity, only accessible by trading firms. Protocols sacrifice 2-5%+ of their token supply to firms for liquidity",
    },
    {
      title: "Lack of transparency",
      text: "Centralized market making firms offer zero insights to exchanges and users as to how liquidity is provisioned",
    },
    {
      title: "Stonewalled from DeFi",
      text: "Decentralized exchanges are unable to access everyday user participation in supplying liquidity",
    },
  ];
  return (
    <Style>
      <div className="container">
        <h3 data-aos="fade-in">
          Re-envisioning the exchange liquidity landscape: introducing a new financial primitive enabling
          liquid markets for orderbook-based exchanges.
        </h3>
        <img src={Background} alt="" data-aos="fade-in" />
        <h2 className="s" data-aos="fade-in">Exchange Liquidity is</h2>
        <h2 data-aos="fade-in"> Broken</h2>
        <div className="row">
          {data.map((item, index) => (
            <Card key={index} n={(index + 1) * 100} title={item.title} text={item.text} />
          ))}
        </div>
      </div>
    </Style>
  );
};
export default MarketingMaking;

const Style = styled.section`
  padding-top: 100px;
  padding-bottom: 80px;
  position: relative;
  min-height: 100vh;

  img {
    position: absolute;
    right: 0;
    top: 100px;
    z-index: -1;
    width: clamp(300px, 50%, 700px);
    opacity: 0.7;
    @media (max-width: 768px) {
      display: none;
    }
  }

  h3 {
    max-width: 823px;
    margin-bottom: 340px;
  }
  h2.s {
    
  }
  }
  .row {
    display: flex;
    margin-top: 100px;
    justify-content: space-evenly;
    gap: 3vw;
    > article {
      min-height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-left: 1px solid #d3d3d3b4;
      padding-left: 40px;
      p {
        margin-top: 16px;
        color: #d3d3d3;
        opacity: 0.8;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 60px;
      > article {
        padding-left: 20px;
        min-height: auto;
      }
    }
  }
  @media (max-width: 768px) {
    min-height: auto;
    h3 {
      margin-bottom: 100px;
    }
  }
`;

const Card = ({ title, text, n }) => {
  return (
    <article data-aos="fade-right" data-aos-delay={n}>
      <h4>{title}</h4>
      <p className="body-2">{text}</p>
    </article>
  );
};
