import Hero from "../sections/Hero";
import MarketMaking from "../sections/MarketMaking";
import PowerOfElixir from "../sections/PowerOfElixir";
import Defi from "../sections/Defi";
import MagicNumber from "../sections/MagicNumber";
import Investors from "../sections/Investors";
import Partners from "../sections/Partners";
import TryOut from "../sections/TryOut";
import Footer from "../sections/Footer";

function Home() {
  return (
    <main>
      <Hero />
      <MarketMaking />
      <PowerOfElixir />
      <Defi />
      <MagicNumber />
      <Investors />
      <Partners />
      <TryOut />
      <Footer />
    </main>
  );
}
export default Home;
