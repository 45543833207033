import styled from "styled-components";
import { ReactComponent as DefiText } from "../assets/images/defi.svg";
import Background from "../assets/images/bg-5.png";
import BackgroundCard1 from "../assets/images/bit-art-1.svg";
import BackgroundCard2 from "../assets/images/bit-art-2.svg";
import BackgroundCard3 from "../assets/images/bit-art-3.svg";
import { Swiper, SwiperSlide } from "swiper/react";

const Defi = () => {
  const cards = [
    {
      step: "01",
      title: "AMMs",
      text: "The protocol enables active market making on concentrated liquidity AMMs. Both projects and venues can access MM on pairs, enabling tighter slippage and ensuring liquidity exists throughout the entire curve.",
    },
    {
      step: "02",
      title: "Exchange Integrations",
      text: "Elixir is fully composable - exchanges can natively integrate Elixir to unlock retail liquidity for algorithmic market making on their platform, vastly improving overall liquidity and capital efficiency.",
    },
    {
      step: "03",
      title: "Central Limit Orderbooks",
      text: "Elixir supports most API-based central limit orderbooks (CLOBs), allowing projects and venues to build algorithmically deployed liquidity on orderbook pairs.",
    }
  ];
  return (
    <Style>
      <div className="container">
        <DefiText className="bg" data-aos="fade-up" />
        <div className="row">
          {cards.map((card, index) => (
            <Card key={index} {...card} n={index} />
          ))}
        </div>
        <div className="carousel">
          <Swiper slidesPerView="auto" spaceBetween={20}>
            {cards.map((card, index) => (
              <SwiperSlide key={index}>
                <Card {...card} n={index} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </Style>
  );
};
export default Defi;

const Style = styled.section`
  padding-top: 100px;
  padding-bottom: 450px;
  background-image: url(${Background});
  background-size: auto;
  background-position: center 130%;
  background-repeat: no-repeat;
  > svg {
    height: auto;
  }

  .bg {
    width: 100%;
    margin-inline: auto;
  }
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: -70px;

    article {
      :nth-child(2) {
        bottom: 50px;
      }
      :nth-child(3) {
        top: 50px;
      }
    }
  }
  .carousel {
    display: none;
    .swiper-slide {
      width: auto;
    }
  }

  @media (max-width: 768px) {
    padding-bottom: 250px;
    background-position: center 20vw;
    > svg {
      margin-bottom: 100px;
    }
    .carousel {
      display: block;
    }
    .row {
      display: none;
    }
  }
`;

const Card = ({ step, title, text, n }) => {
  return (
    <CardStyle data-aos="fade-in" n={n}>
      <span>{step}</span>
      <h4>{title}</h4>
      <p className="body-2">{text}</p>
    </CardStyle>
  );
};
const CardStyle = styled.article`
  background-color: #fff;
  color: #020101;
  border-radius: 10px;
  padding: 20px;
  ${({ n }) => {
    switch (n) {
      case 0:
        return `
          background-image: url(${BackgroundCard1});
        `;
      case 1:
        return `
          background-image: url(${BackgroundCard2});
        `;
      case 2:
        return `
          background-image: url(${BackgroundCard3});
        `;
      default:
        return;
    }
  }}
  background-position: top right;
  background-repeat: no-repeat;
  width: 330px;
  min-height: 510px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-right: 20px;

  span {
    font-size: 1rem;
    font-family: "Mundial Light";
    width: 37px;
    height: 37px;
    border-radius: 50px;
    border: 1px solid #020101;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 13px;
  }

  p {
    margin-top: auto;
  }

  @media (max-width: 768px) {
    position: static;
    width: 260px;
    min-height: 500px;
  }
`;
