import styled from "styled-components";
import { Link } from "react-router-dom";

import Background from "../assets/images/hero-bg.png";
import Logo from "../assets/images/logo-light.png";
import { ReactComponent as Discord } from "../assets/images/discord.svg";
import { ReactComponent as Telegram } from "../assets/images/telegram.svg";
import { ReactComponent as Twitter } from "../assets/images/twitter.svg";

const Hero = () => {
  return (
    <Style data-aos="fade">
      <Link to="/">
        <img src={Logo} alt="logo" data-aos="fade-in" width={143} height={37} className="logo" />
      </Link>
      <div className="social-media" data-aos="fade-in">
        <a href="https://discord.gg/elixirnetwork">
          <Discord />
        </a>
        <a href="https://t.me/elixir_network">
          <Telegram />
        </a>
        <a href="https://twitter.com/ElixirProtocol">
          <Twitter />
        </a>
      </div>
      <a href="https://app.elixir.finance/" className="btn">
        Launch Interface
      </a>
      <div className="container" data-aos="fade-up">
        <h1 className="s">Orderbooks.</h1>
        <h1>Reimagined.</h1>
        <p className="body-1">
        A high-throughput DPoS network. Enabling anyone to supply liquidity to exchanges.
        </p>
      </div>
    </Style>
  );
};
export default Hero;

const Style = styled.header`
  padding-top: 160px;
  padding-bottom: 80px;
  height: 160vh;
  max-height: 1900px;
  background-image: url(${Background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  position: relative;

  .logo {
    position: absolute;
    top: 40px;
    left: 35px;
    height: auto;
  }
  .social-media {
    position: absolute;
    top: 40px;
    right: 35px;
    padding: 20px 11px;
    background: #020101;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 60px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: center;
    align-items: center;
    a:hover svg path {
      fill: #5a54dd;
    }
  }
  .btn {
    position: absolute;
    top: 50%;
    right: -20px;
    transform: translateY(-50%) rotate(-90deg);
  }
  h1.s {
    margin-bottom: -25px;
  }
  p {
    max-width: 486px;
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    height: 750px;
    background-size: 160vw;
    padding-top: 120px;
    .logo {
      top: 18px;
      left: 20px;
      width: 87px;
    }
    .social-media {
      display: none;
    }
    .btn {
      top: 20px;
      right: 20px;
      transform: none;
    }
    h1.s {
      margin-bottom: 0;
    }
  }
`;
