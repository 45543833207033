import React from "react";
import styled from "styled-components";
import { ReactComponent as ChartExample } from "../assets/images/chart.svg";

const Chart = () => {
  return (
    <Style data-aos="fade-left">
      <ChartExample />
    </Style>
  );
};

export default Chart;

const Style = styled.div`
  background: #f2f2f2;
  border-radius: 20px;
  padding: 100px 70px 70px;
  width: 100%;
  max-width: 1066px;
  height: auto;
  margin-left: auto;
  margin-top: -80px;

  @media (max-width: 768px) {
    padding: 50px 30px 30px;
    margin-top: 23px;
  }

  svg {
    width: 100%;
    height: 100%;
  }
`;
