import styled from "styled-components";
import Waves from "../assets/images/bg-waves.svg";
import Carousel from "../components/Carousel";
import Chart from "../components/Chart";

const MagicNumber = () => {
  return (
    <Style>
      <img src={Waves} alt="" className="waves" />
      <div className="container">
        <h1 data-aos="fade-in">Supercharging</h1>
        <h1 data-aos="fade-in" className="s">
          Liquidity
        </h1>
        <Chart />
        <Carousel />
      </div>
    </Style>
  );
};
export default MagicNumber;

const Style = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  position: relative;
  background-color: #fff;
  color: #020101;
  padding-bottom: 350px;
  h1 {
    z-index: 1;
    position: relative;
  }

  .waves {
    position: absolute;
    top: -15vw;
    right: 0;
    left: 0;
    width: 100%;
  }
  .container {
    margin-top: -10vw;
    position: relative;
  }
`;
