import React, { useState } from "react";
import styled from "styled-components";
import Illustration from "../assets/images/illu-3.png";
import Illustration4 from "../assets/images/illu-1-1.png";
import Illustration1 from "../assets/images/illu-2-1.png";
import Illustration2 from "../assets/images/illu-5.png";
import Illustration3 from "../assets/images/illu-2.png";
import Gradient from "../assets/images/gradient.svg";
import { ReactComponent as Arrow } from "../assets/images/arrow-up.svg";

const PowerOfElixir = () => {
  const data = [
    {
      number: "01",
      title: "Protocol Positioning",
      text: "Elixir is a decentralized DPoS network offering computationally-secure, algorithmic market making across exchanges: offering full composability for native integrations",
      img: Illustration1,
    },
    {
      number: "02",
      title: "Democratized MM Firm Returns",
      text: "Trading firm profits are democratized, allowing exchanges and protocols to drive those returns to their communities",
      img: Illustration,
    },
    {
      number: "03",
      title: "Supply Liquidity to Orderbooks",
      text: "Deposit USDC or USDT directly to exchange pairs via their native integration of Elixir. The protocol trustlessly builds up the orderbooks",
      img: Illustration2,
    },
    {
      number: "04",
      title: "Full Transparency",
      text: "Elixir’s trustless, transparent architecture enables projects and users to know exactly how the protocol provisions liquidity",
      img: Illustration3,
    },
  ];
  return (
    <Style>
      <div className="container">
        <div className="desktop">
          <header data-aos="fade-in">
            <h2>The Power </h2>
            <img src={Illustration4} width="170" alt="" />
            <h2>of Elixir</h2>
          </header>
          <div>
            {data.map((item, index) => (
              <Row
                key={index}
                number={item.number}
                title={item.title}
                text={item.text}
                img={item.img}
              />
            ))}
          </div>
        </div>
        <div className="mobile">
          <header>
            <h2>The Power of Elixir</h2>
            <img src={Illustration4} alt="" />
          </header>
          <div>
            {data.map((item, index) => (
              <Row
                key={index}
                number={item.number}
                title={item.title}
                text={item.text}
                img={item.img}
              />
            ))}
          </div>
        </div>
      </div>
    </Style>
  );
};
export default PowerOfElixir;

const Style = styled.section`
  padding-top: 80px;

  header {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 57px;
    @media (max-width: 768px) {
      img {
        width: 114px;
        height: 114px;
      }
    }
  }
  .mobile {
    display: none;
    header {
      margin-bottom: 40px;
      h2 {
        max-width: 160px;
      }
    }
  }
  @media (max-width: 768px) {
    .mobile {
      display: flex;
      flex-direction: column;
    }
    .desktop {
      display: none;
    }
  }
`;

const Row = ({ number, title, text, img }) => {
  const [open, setOpen] = useState(false);

  return (
    <RowStyle data-aos="fade-in">
      <div className="title">
        <span className="number">
          <span>{number}</span>
        </span>
        <h4>{title}</h4>
        <button className={`${open ? "active" : ""}`} onClick={() => setOpen((prev) => !prev)}>
          <Arrow />
        </button>
      </div>
      <div className={`img ${open ? "active" : ""}`}>
        <img src={img} alt={title} />
      </div>
      <p className={`body-2 ${open ? "active" : ""}`}>{text}</p>
    </RowStyle>
  );
};
const RowStyle = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: #fff;
  opacity: 0.3;
  transition: opacity 0.3s ease-in-out;
  :hover {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
    .img {
      transition: opacity 0.3s ease-in-out;
      opacity: 1;
    }
  }
  .title {
    display: flex;
    align-items: center;
    gap: 20px;
    max-width: 320px;
    button {
      background: none;
      border: none;
      margin-left: auto;
      display: none;
      svg {
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
        transform: rotate(180deg);
        path {
          fill: #fff;
        }
      }
      &.active {
        svg {
          transform: rotate(0deg);
          transition: transform 0.3s ease-in-out;
        }
      }
    }
  }
  .img {
    width: clamp(150px, 30vw, 270px);
    height: clamp(150px, 30vw, 270px);
    position: relative;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      position: absolute;
    }
  }
  .number {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-image: url(${Gradient});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    min-width: 37px;
    height: 37px;
    span {
      letter-spacing: 0.14em;
      text-transform: uppercase;
      font-size: 1rem;
      background: linear-gradient(208.42deg, #ffe47c 6.63%, #ff43c3 42.28%, #4156e0 84.17%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  p {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #ffffff40;
    opacity: 1;
    .number {
      margin-bottom: 0;
      background: none;
      width: 37px;
      height: 37px;
      border: 1px solid;

      span {
        color: #fff;
        background: none;
        -webkit-background-clip: none;
        -webkit-text-fill-color: #fff;
      }
    }
    .title {
      margin-bottom: 13px;
      max-width: 100%;
      width: 100%;
      button {
        display: block;
      }
    }
    .img {
      margin-inline: auto;
      margin-top: 60px;
      order: 3;
      width: 300px;
      height: 300px;
      opacity: 1;
      display: none;
      &.active {
        display: block;
      }
    }
    p {
      display: none;
      &.active {
        display: block;
      }
    }
  }
`;
