import styled from "styled-components";
import Illustration from "../assets/images/illus-2.png";
const TryOut = () => {
  return (
    <Style>
      <div className="container" data-aos="fade-in">
        <header className="desktop">
        <h1 className="s">Ready to </h1>
          <h1>try</h1>
          <img src={Illustration} alt="" />
          <h1>Elixir?</h1>
        </header>
        <header className="mobile">
          <div>
            <h1 className="s">Ready to </h1>
            <h1>try</h1>
            <h1>Elixir?</h1>
          </div>
          <img src={Illustration} alt="" />
        </header>
        <div className="text">
          <p className="body-1">
            Join over 50 cryptoassets committed to using Elixir for market making:
          </p>
          <div>
            <a href="mailto:info@elixir.finance" className="btn-lg">
              Contact us
            </a>
            <a href="https://docs.elixir.finance/" className="btn-lg">
              Read docs
            </a>
          </div>
        </div>
      </div>
    </Style>
  );
};
export default TryOut;

const Style = styled.section`
  padding-top: 90px;
  padding-bottom: 80px;

  header {
    max-width: 790px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  .mobile {
    display: none;
  }
  img {
    width: 152px;
    height: 152px;
  }
  .text {
    margin-top: 80px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
    > div {
      display: flex;
      gap: 30px;
    }
    p {
      max-width: 423px;
    }
    @media (max-width: 768px) {
      flex-direction: column;
      gap: 57px;
      > div {
        justify-content: center;
      }
    }
  }

  @media (max-width: 768px) {
    img {
      width: 140px;
      height: 140px;
    }
    .text {
      margin-top: 30px;
    }
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
      flex-wrap: nowrap;
      gap: 20px;
    }
  }
`;
