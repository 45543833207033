import styled from "styled-components";

import { ReactComponent as Partner1 } from "../assets/images/partners/1.svg";
import { ReactComponent as Partner2 } from "../assets/images/partners/2.svg";
import { ReactComponent as Partner3 } from "../assets/images/partners/3.svg";
import { ReactComponent as Partner4 } from "../assets/images/partners/4.svg";
import { ReactComponent as Partner5 } from "../assets/images/partners/5.svg";
import { ReactComponent as Partner6 } from "../assets/images/partners/6.svg";

const Partners = () => {
  return (
    <Style data-aos="fade-in">
      <div className="container">
        <p className="sub-2">Elixir's Native Integrations:</p>
        <div className="row">
          <Partner1 />
          <Partner2 />
          <Partner3 />
          <Partner4 />
          <Partner5 />
          <Partner6 />
        </div>
      </div>
    </Style>
  );
};
export default Partners;

const Style = styled.section`
  padding-top: 80px;
  padding-bottom: 80px;
  p {
    text-align: center;
    margin-bottom: 40px;
  }
  .row {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 50px;
    flex-wrap: wrap;
  }
`;
